




































































































































































import Vue from "vue";
import {
  AddJobDetails,
  SelectedJob
} from "@/interfaces/recruiter/jobs/post_job/add_job_details";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  COMMON_LOADING,
  GET_JOB_BY_ID,
  GET_UNIQUE_SECTORS,
  SITE_DIRECTION,
  VIEW_JOB_STATE
} from "@/store/modules/common/constants";
import { VForm } from "@/interfaces/v_form";
import {
  RECRUITER_LOADING,
  POST_JOB,
  RECOMMEND_JOB_PROFILES,
  SAVE_UPDATED_JOB_DESCRIPTION
} from "@/store/modules/recruiter/constants";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import {
  SiteDirections,
  ViewJobState
} from "@/store/modules/common/interfaces";
import router from "@/router";
import AutoCompleteCustomJobSearch from "@/components/shared/jobs/AutoCompleteCustomJobSearch.vue";
import { clean_string } from "@/utils/global";
import { SaveUpdatedJobDescriptionPayload } from "@/store/modules/recruiter/interfaces";

export default Vue.extend({
  name: "AddJobDetails",
  components: { AutoCompleteCustomJobSearch },
  data(): AddJobDetails & {
    answer_editor_options: any;
    is_job_description_edited: boolean;
    is_touched: boolean;
  } {
    return {
      form: false,
      job_title: "",
      job_industry: null,
      job_type: null,
      job_experience: "",
      job_description: "",
      standardised_selected: false,
      standardised_job: null,
      job_types: [
        {
          text: this.$t("recruiter.post-job.manual.job-type-op1"),
          value: "Full Time"
        },
        {
          text: this.$t("recruiter.post-job.manual.job-type-op2"),
          value: "Part Time"
        }
      ],
      job_title_rules: [
        (value: string) =>
          !!value.trim() || this.$t("recruiter.field-required-rule")
      ],
      job_industry_rules: [
        (value: string) => !!value || this.$t("recruiter.field-required-rule")
      ],
      job_type_rules: [
        (value: string) => !!value || this.$t("recruiter.field-required-rule")
      ],
      job_experience_rules: [
        // (value: string) =>
        //   !!value.trim() || this.$t("recruiter.field-required-rule")
      ],
      job_description_rules: [
        (value: string) =>
          !!value.trim() || this.$t("recruiter.field-required-rule"),
        (value: string) =>
          value.trim().length > 70 || this.$t("recruiter.description-min-rule")
        // (value: string) =>
        //   value.trim().length < 700 || this.$t("recruiter.description-max-rule")
      ],
      answer_editor_options: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"]
          ]
        },
        placeholder: "Type answer here",
        table: [] // Answer editor options
      },
      is_job_description_edited: true,
      is_touched: false
    };
  },
  mounted() {
    if (this.unique_sectors.length === 0) this.init_sectors();
  },
  watch: {
    job_description(val: string) {
      this.is_touched = true;
      if (val.length >= 0 && val.length <= 70)
        this.is_job_description_edited = false;
      else this.is_job_description_edited = true;
    }
  },
  computed: {
    ...mapGetters("common", {
      unique_sectors: GET_UNIQUE_SECTORS,
      common_loading: COMMON_LOADING,
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("recruiter", {
      post_job_loading: RECRUITER_LOADING
    }),
    job_form(): VForm {
      return this.$refs.job_form as VForm;
    }
  },
  methods: {
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    ...mapActions("common", {
      init_sectors: GET_UNIQUE_SECTORS,
      get_job_by_id: GET_JOB_BY_ID
    }),
    ...mapMutations("common", {
      set_view_job_state: VIEW_JOB_STATE
    }),
    ...mapActions("recruiter", {
      post_job: POST_JOB,
      save_updated_job_description: SAVE_UPDATED_JOB_DESCRIPTION
    }),
    ...mapMutations("recruiter", {
      set_recommend_job_profiles: RECOMMEND_JOB_PROFILES
    }),
    /**
     * Function to set title and fetch job description
     * @param val
     */
    async search_job(val: SelectedJob | null | string) {
      // If value is not selected or selected
      if (!val) {
        this.job_title = "";
        // // If standardised job selected and user unselect standardised job => reset job_description
        // if (this.standardised_selected) this.job_description = "";
        this.standardised_selected = false;
        this.standardised_job = null;
        return;
      }
      // If value is custom
      if (typeof val === "string") {
        this.job_title = val;
        this.standardised_selected = false;
        this.standardised_job = null;
        return;
      }
      this.standardised_selected = true;
      // If value from dropdown
      this.job_title = val.text;
      // Fetch job description
      const job_details = await this.get_job_by_id(val.job_id);
      if (job_details) {
        this.job_description = job_details.job_description;
        this.job_industry = job_details.sector;
        this.standardised_job = job_details;
      }
    },
    async submit_form() {
      if (this.job_form.validate()) {
        let is_standardize_job_edited = false;
        const obj: FormData = new FormData();

        if (this.standardised_selected && this.standardised_job) {
          const is_same_description =
            clean_string(this.job_description) ===
            clean_string(this.standardised_job.job_description ?? "");
          const is_same_sector =
            clean_string(this.job_industry ?? "") ===
            clean_string(this.standardised_job.sector ?? "");
          if (!is_same_description) {
            const payload: SaveUpdatedJobDescriptionPayload = {
              job_id: this.standardised_job.jid,
              description: this.job_description,
              sector: this.job_industry ?? ""
            };
            // Saving job description in DB
            await this.save_updated_job_description(payload);
          } else if (!is_same_sector) {
            const payload: SaveUpdatedJobDescriptionPayload = {
              job_id: this.standardised_job.jid,
              description: this.job_description,
              sector: this.job_industry ?? ""
            };
            await this.save_updated_job_description(payload);
          }
          is_standardize_job_edited = is_same_description && is_same_sector;
        }

        if (is_standardize_job_edited) {
          const payload = {
            is_standardize: true,
            job_id: this.standardised_job?.jid.toString(),
            edited: false
          };
          for (let [key, value] of Object.entries(payload))
            obj.append(key, value as string);
        } else {
          obj.append("job_title", this.job_title);
          obj.append("job_industry", this.job_industry ?? "");
          obj.append("job_type", this.job_type ?? "");
          obj.append("job_experience", this.job_experience);
          obj.append("job_description", this.job_description);
        }

        obj.append("post_type", "text");
        const job_posted = await this.post_job(obj);
        // If job not posted successfully
        if (!job_posted) {
          this.root_error(this.$t("errors.job-post-failed"));
        } // If job posted successfully
        else {
          this.root_notification(
            this.$t("success-messages.job-parsed-success")
          );
          // Set recommend job profiles
          this.set_recommend_job_profiles(job_posted.recommend_job_profiles);
          // Set view job state
          this.set_view_job_state(ViewJobState.TEXT_JOB_POST);
          const job_id = job_posted.standardized_data.jid;
          // Navigate to jobs page
          await router.push(`/job/${job_id}`);
        }
      }
    }
  }
});
